<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.EventOrganizer.Add.Main_Title')"
    :disableSaveButton="disableSaveButton"
    :buttonLabelConfirm="$t('Form.Control.Add')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :onClickCancel="onClickCancel"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :showModal="showModal"
    :onClickSave="uploadAndCreate"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <td>
              {{ $t('Components.Events.EventOrganizer.Add.Label_Name') }}
            </td>
            <td>
              <ui-base-input-field
                v-model="newOrganizer.Name"
                :error="nameErrors"
                :required="true"
                :showLabel="false"
                fieldType="text"
                @input="$v.newOrganizer.Name.$touch()"
                @blur="$v.newOrganizer.Name.$touch()"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Events.EventOrganizer.Add.Label_Email') }}
            </td>
            <td>
              <ui-base-input-field
                v-model="newOrganizer.Email"
                :error="emailErrors"
                :required="true"
                :showLabel="false"
                fieldType="text"
                @input="$v.newOrganizer.Email.$touch()"
                @blur="$v.newOrganizer.Email.$touch()"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Events.EventOrganizer.Add.Label_Phone') }}
            </td>
            <td>
              <input
                type="text"
                v-model="newOrganizer.Phone"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('Components.Hosts.ModalCreateHost.Label_Image') }}</td>
            <td>
              <div class="imageContainer">
                <ImageCropper
                  ref="imageCropper"
                  :aspectRatio="1"
                  :viewMode="3"
                  :guides="false"
                  aspectRatioCssClass="is-1by1"
                  imageContainerCssClass="is-rounded"
                  uploadButtonView="icon-text"
                  :isStatic="isSystemProfile"
                  @callbackImageChosen="val => { newImageChosen = val }"
                  @errorCallbackInvalid="val => { hasImageError = val }"
                  class="organizerImage"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import eventProvider from '@/providers/event'
import uploadProvider from '@/providers/upload'

const ImageCropper = () => import('@/components/UI/Components/ImageCropper')

import { required, minLength, email } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {
    ImageCropper,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newImageChosen: false,
      hasImageError: false,
      newOrganizer: null,
      mEvent: null,
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Name: {
        required,
        minLength: minLength(2),
      },
      Email: {
        required,
        email,
      },
    }
    return {
      newOrganizer: output,
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),

    disableSaveButton() {
      return this.isSaving || this.hasImageError || this.nameErrors.length || this.emailErrors.length
    },

    nameErrors() {
      const errors = []
      if (!this.$v.newOrganizer.Name.$dirty) return errors
      !this.$v.newOrganizer.Name.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.newOrganizer.Name.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
      return errors
    },

    emailErrors() {
      const errors = []
      if (!this.$v.newOrganizer.Email.$dirty) return errors
      if (!this.$v.newOrganizer.Email.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      } else if (!this.$v.newOrganizer.Email.email) {
        errors.push(this.$t('Form.InputErrors.InvalidEmail'))
      }
      return errors
    },
  },

  created() {
    this.mEvent = JSON.parse(JSON.stringify(this.eventData))

    this.newOrganizer = {
      Id: 0,
      ProfileId: 0,
      EventId: this.mEvent.Id,
      Name: '',
      Email: '',
      Phone: '',
      Image: '',
      IsPrimary: this.mEvent.Organizers.length === 0,
      ReceiveCheckinMail: true,
    }
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    async uploadAndCreate() {
      await this.$v.$touch()
      if (this.disableSaveButton) { return }
      this.isSaving = true

      try {
        if (this.newImageChosen) {
          const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
          let imageUploadResponse = await uploadProvider.methods.eventImage(this.newOrganizer.EventId, NEW_IMAGE)
          if (imageUploadResponse.status === 200) {
            this.newOrganizer.Image = imageUploadResponse.data
          }
          this.createOrganizer()
        }
      } catch {
        this.isSaving = false
        this.isSavingError = true
      }
    },

    createOrganizer() {
      let self = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        // 'ERROR'
        self.isSaving = false
      } else {
        self.mEvent.Organizers.push(this.newOrganizer)
        eventProvider.methods
          .updateEvent(self.mEvent)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.setEventData(response.data)

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((e) => {
            ////console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  z-index: 102;
}
.table {
  td {
    vertical-align: middle;
    .imageContainer {
      max-width: 240px;
    }
  }
}
</style>
